<template>
  <div>
    <v-container class="py-0">
      <transition name="fade" mode="out-in" appear>
        <PageTitle class="d-block d-sm-none">Invoices</PageTitle>
      </transition>

      <transition name="slide-left-fade" mode="out-in" appear>
        <div class="d-flex align-start pt-3 pt-sm-0">
          <AddInvoiceButton v-if="$store.state.InvoiceRecords.submittedDeductionRecords" />
          <v-spacer></v-spacer>
          <div class="d-none d-sm-block">
            <PageTitle>Invoices</PageTitle>
          </div>
        </div>
      </transition>

      <transition name="slide-fade" mode="out-in" appear>
        <div>
          <!-- Main content -->

      
          <InvoiceSubmittedDeductionsTable v-if="$store.state.InvoiceRecords.submittedDeductionRecords" />
          <Skeleton v-else />

        </div>
      </transition>
    </v-container>
  </div>
</template>

<script>
import PageTitle from '@/components/Slots/PageTitle.vue'
import InvoiceSubmittedDeductionsTable from '@/components/Invoices/InvoiceSubmittedDeductionsTable.vue';
import AddInvoiceButton from '@/components/Invoices/AddInvoiceButton.vue';
import Skeleton from '../components/SkeletonLoaders/SkeletonLoaderTable.vue'


export default {
  name: 'invoices',
  components: {
    PageTitle,
    InvoiceSubmittedDeductionsTable,
    AddInvoiceButton,
    Skeleton
  },
  created() {
    this.$store.dispatch('InvoiceRecords/getSubmittedDeductionRecords')
  },
}
</script>

<style scoped>

</style>