<template>
  <div>
    <v-card>
      <v-card-title class="dialogColor white--text" color="secondary">
        Attached documents
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog()" text color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="invoice" class="pt-5">
        <!-- {{ invoice }}
        {{ invoice.Address }} -->
        <div 
          v-for="doc in invoice.Documents" 
          :key="doc.OID"
          class="mb-3"
        >
          <div class="d-flex justify-space-between align-center">
            <p class="my-0 text-body-1">{{ doc.Filename }}</p>
            <v-btn @click="downloadDoc(doc)" color="secondary" text>
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
        </div>
        <p v-if="!invoice.Documents.length" class="text-h6">No attached documents present</p>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'attachedDocsCard',
  props: {
    invoice: [Object, String]
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    downloadDoc(doc) {
      this.$store.dispatch('InvoiceRecords/downloadDocument', doc.OID)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL
          fileLink.setAttribute('download', `${doc.Filename}`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
    }
  }
}
</script>

<style>

</style>