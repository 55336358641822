<template lang="html">
  <div>
    <v-card flat v-if="$store.state.InvoiceRecords.submittedDeductionRecords">
      <v-card-title class="secondary white--text">
        <div class="ma-0">
          <div class="d-flex align-center">
            <v-icon large left color="white">mdi-file-document-multiple</v-icon>
            <p class="subtitle-1 my-0">{{ $store.state.InvoiceRecords.submittedDeductionRecords.length }} Invoices</p>
            <v-divider vertical class="mx-3 my-1" color="white"></v-divider>
            <span class="subtitle-1">{{ totalPayments }}</span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <TableSearch @input="(newText) => {search = newText}" />
      </v-card-title>

      <v-data-table
        class="tableColor"
        :search="search"
        :headers="headers"
        :items="$store.state.InvoiceRecords.submittedDeductionRecords"
        :items-per-page="-1"
        sort-by="State"
        sort-desc
      >
        <template v-slot:item.amount="{ item }">
          <span>
            {{ item.Amount | formatAmount }}
          </span>
        </template>

        <template v-slot:item.State="{ item }">
          <div>
            <div
              v-if="item.State === 'Submitted'"
              class="d-flex align-center justify-center"
            >
              <span class="blue--text text-capitalize">{{ item.State }}</span>
              <v-icon right small color="blue">mdi-send</v-icon>
            </div>
            <div
              v-if="item.State === 'Authorised'"
              class="d-flex align-center justify-center"
            >
              <span class="green--text text-capitalize">{{ item.State }}</span>
              <v-icon right small color="green">mdi-check</v-icon>
            </div>
            <div
              v-if="item.State === 'Rejected'"
              class="d-flex align-center justify-center"
            >
              <span class="red--text text-capitalize">{{ item.State }}</span>
              <v-icon right small color="red">mdi-cancel</v-icon>
            </div>
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-menu  offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                small
                depressed
                v-bind="attrs"
                v-on="on"
                class="text-capitalize"
              >
                Actions
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0 secondary" dark>
              <v-list-item v-if="item.State === 'Submitted'" @click="openActionDialog(item, 'authorise')">
                <v-list-item-title>Authorize</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-check</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item v-if="item.State === 'Submitted'" @click="openActionDialog(item, 'reject')">
                <v-list-item-title>Reject</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-delete</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="openActionDialog(item, 'archive')">
                <v-list-item-title>Archive</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-archive-plus</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="openAttachmentsDialog(item)">
                <v-list-item-title>View attachments</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-paperclip</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <Snackbar 
      :snackbarDialog="snackbar" 
      :snackbarText="snackbarText" 
      :snackbarColor="snackbarColor"
      @closeSnackbar="closeSnackbar()"
      :timeout="timeout"
    />

    <v-dialog
      v-model="attachmentsDialog"
      width="500px"
      transition="dialog-transition"
    >
      <AttachedDocsCard
        :invoice="selectedItem" 
        @closeDialog="closeAttachmentsDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="actionDialog" 
      persistent    
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="dialogColor white--text">
          Confirm action
        </v-card-title>
        <v-card-text class="pt-5">
          <p class="text-body-1" v-if="actionDialogType === 'authorise'">Do you want to authorise the following invoice:</p>
          <p class="text-body-1" v-if="actionDialogType === 'reject'">Do you want to reject the following invoice:</p>
          <p class="text-body-1" v-if="actionDialogType === 'archive'">Do you want to archive the following invoice:</p>

          <p class="mb-1 font-weight-bold">{{ selectedItem.Contractor.Name }}</p>
          <p class="my-0">{{ selectedItem.Amount | formatAmount }}</p>
          <p class="my-0">{{ selectedItem.Address }}</p>
          <p class="my-0">{{ selectedItem.Description }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeActionDialog" color="red" outlined>cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            v-if="actionDialogType === 'authorise'" 
            @click="authoriseInvoice"
            width="30%" 
            color="success"
          >Authorise</v-btn>
          <v-btn 
            v-if="actionDialogType === 'reject'" 
            @click="rejectInvoice"
            width="30%" 
            color="success"
          >Reject</v-btn>
          <v-btn 
            v-if="actionDialogType === 'archive'" 
            @click="archiveInvoice"
            width="30%" 
            color="success"
          >Archive</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import TableSearch from "@/components/TableComponents/TableSearch.vue";
import Snackbar from "@/components/Snackbars/TheSnackbar";
import AttachedDocsCard from "@/components/Invoices/AttachedDocsCard.vue";

export default {
  name: "invoiceSubmittedDeductionsTable",
  components: {
    TableSearch,
    Snackbar,
    AttachedDocsCard,
  },
  data() {
    return {
      attachmentsDialog: false,
      loading: false,
      search: "",
      selectedItem: "",
      actionDialog: false,
      actionDialogType: "",
      headers: [
        { text: "Contractor", value: "Contractor.Name", align: "left" },
        { text: "Property", value: "Address", sortable: true },
        { text: "Description", value: "Description", sortable: false },
        { text: "Amount", value: "amount", sortable: false }, // sortable: true, sort: (a, b) => b - a
        { text: 'Status', value: 'State'},
        { text: "Actions", value: "action", align: "center", sortable: false },
      ],
      // snackbar data
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      timeout: 3000,
    };
  },
  methods: {
    openActionDialog(invoice, type) {
      this.selectedItem = invoice
      this.actionDialogType = type
      this.actionDialog = true
    },
    closeActionDialog() {
      this.actionDialog = false
      this.actionDialogType = ''
      this.selectedItem = ''
    },
    openAttachmentsDialog(item) {
      this.selectedItem = item
      this.attachmentsDialog = true
    },
    closeAttachmentsDialog() {
      this.attachmentsDialog = false
      this.selectedItem = ""
    },
    authoriseInvoice() {
      this.$store
        .dispatch("InvoiceRecords/authoriseContractorInvoice", this.selectedItem.OID)
        .then(() => {
          this.$nextTick(() => this.$store.dispatch("InvoiceRecords/getSubmittedDeductionRecords"))
          this.closeActionDialog()
          this.snackbar = true
          this.snackbarColor = "green"
          this.snackbarText = "Invoice successfully authorised."
        })
        .catch((err) => {
          console.log("Error: ", err)
          this.closeActionDialog()
          this.snackbar = true
          this.snackbarColor = "red"
          this.snackbarText = "Error authorising invoice. Please try again or contact support"
        })
    },
    rejectInvoice() {
      this.$store
        .dispatch("InvoiceRecords/rejectContractorInvoice", this.selectedItem.OID)
        .then(() => {
          this.$nextTick(() => this.$store.dispatch("InvoiceRecords/getSubmittedDeductionRecords"))
          this.closeActionDialog()
          this.snackbar = true
          this.snackbarColor = "green"
          this.snackbarText = "Invoice successfully rejected."
        })
        .catch((err) => {
          console.log("Error: ", err)
          this.closeActionDialog()
          this.snackbar = true
          this.snackbarColor = "red"
          this.snackbarText = "Error rejecting invoice. Please try again or contact support"})
    },
    archiveInvoice() {
      this.$store
        .dispatch("InvoiceRecords/archiveContractorInvoice", this.selectedItem.OID)
        .then( () => {
          this.$nextTick(() => this.$store.dispatch("InvoiceRecords/getSubmittedDeductionRecords"))
          this.closeActionDialog()
          this.snackbar = true
          this.snackbarColor = "green"
          this.snackbarText = "Invoice successfully archived."
        })
        .catch((err) => {
          console.log("Error: ", err)
          this.closeActionDialog()
          this.snackbar = true
          this.snackbarColor = "red"
          this.snackbarText = "Error archiving invoice. Please try again or contact support"})
    },
    closeSnackbar() {
      //emitted event
      this.snackbar = false
    },
  },
  computed: {
    totalPayments() {
      // only items with state of "Submitted"
      const array = this.$store.state.InvoiceRecords.submittedDeductionRecords;
      const payments = array.map((val) => {
        if (val.State === "Submitted") return val.Amount
        return 0
      });
      const total = payments.reduce((acc, val) => acc + val, 0)
      // return total.toLocaleString()
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(total)
    },
  }
};
</script>





