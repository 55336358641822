<template>
  <div>
    <div class="d-flex my-5">
      <v-spacer></v-spacer>
      <v-btn color="blue" dark depressed @click="dialog = true">
        <v-icon left>mdi-plus</v-icon>
        Add invoice
      </v-btn>
    </div>

    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      transition="dialog-transition"
      width="500"
    >
      <v-card>
        <v-card-title class="primary white--text pr-4" color="secondary">
          Add invoice
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" fab small text color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form>
            <v-row>
              <v-col cols="12">
                <!-- Search for property -->
                <Search
                  label="Property"
                  @selectedOID="fetchProperty"
                  endpoint="property"
                  myParams="address"
                  ref="searchPropertyComponent"
                />
                <!-- Search for contractor -->
                <Search
                  label="Contractor"
                  @selectedOID="fetchContractor"
                  endpoint="contractors"
                  myParams="name"
                  ref="searchContractorComponent"
                />
                <!-- Amount -->
                <v-text-field
                  color="secondary"
                  v-model.number="amount"
                  label="Invoice amount"
                  prefix="£"
                  outlined
                ></v-text-field>
                <!-- Descritpion -->
                <v-textarea
                  color="secondary"
                  label="Description"
                  v-model="description"
                  outlined
                  auto-grow
                  clearable
                  rows="3"
                ></v-textarea>
                <!-- Upload doc -->
                <v-file-input
                  @change="uploadDocuments"
                  @click:clear="clearUploadedDocs"
                  accept=".pdf"
                  color="secondary"
                  label="Attach PDF documents"
                  small-chips
                  outlined
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  multiple
                  clearable
                  ref="fileInput"
                  :key="componentKey"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>

          <v-btn
            :disabled="checkFormFields"
            @click="submitNewInvoice"
            @clear="emptyInputFiles"
            :loading="loading"
            depressed
            block
            large
            color="green d-inline"
            class="mr-3 white--text"
          >
            Save
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Snackbar
      :snackbarDialog="snackbar"
      :snackbarText="snackbarText"
      :timeout="timeout"
      :snackbarColor="snackbarColor"
      @closeSnackbar="closeSnackbar()"
    />
  </div>
</template>

<script>
import Search from "@/components/CloudSearch/Search";
import Snackbar from "@/components/Snackbars/TheSnackbar";
import axios from "axios";

export default {
  name: "addInvoiceButton",
  components: {
    Search,
    Snackbar,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      propertyID: "",
      contractorID: "",
      amount: "",
      description: "",
      // inputedFiles: null,
      attachedDocuments: [],
      // snackbar
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      timeout: 3000,
      componentKey: 0
    };
  },
  computed: {
    checkFormFields() {
      if (this.propertyID && this.contractorID && this.amount) return false;
      return true;
    },
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false;
    },
    closeDialog() {
      this.$refs.searchPropertyComponent.clearItem();
      this.property = "";
      this.$refs.searchContractorComponent.clearItem();
      this.contractor = "";
      this.amount = "";
      this.description = "";
      // this.inputedFiles = null;
      this.clearUploadedDocs();
      this.dialog = false;
    },
    emptyInputFiles() {
      this.attachedDocuments = [];
    },
    fetchProperty(id) {
      this.propertyID = id;
    },
    fetchContractor(id) {
      this.contractorID = id;
    },
    uploadDocuments(files) {
      files.forEach((file) => {
        this.createBase64String(file);
      });
    },
    clearUploadedDocs() {
      this.attachedDocuments = [];
      this.componentKey++;
    },
    createBase64String(fileObject) {
      const reader = new FileReader();

      reader.onload = () => {
        const baser64String = reader.result.split("base64,")[1];
        const documentObject = {
          DocumentName: fileObject.name,
          MimeType: fileObject.type,
          Content: baser64String,
          // Size: fileObject.size
        };
        this.attachedDocuments.push(documentObject);
      };
      reader.readAsDataURL(fileObject);
    },
    submitNewInvoice() {
      this.loading = true;
      const submissionModel = {
        MaitenanceJobID: "",
        ContractorID: this.contractorID,
        PropertyID: this.propertyID,
        Amount: this.amount,
        Description: this.description,
        Documents: this.attachedDocuments,
      };

      axios({
        method: "POST",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/contractors/deductions`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
        data: submissionModel,
      })
        .then(() => {
          this.loading = false;
          this.closeDialog();
          this.snackbar = true;
          this.snackbarText = "Invoice submitted.";
          this.snackbarColor = "green";
          this.$nextTick(() =>
            this.$store.dispatch("InvoiceRecords/getSubmittedDeductionRecords")
          );
        })
        .catch(() => {
          this.loading = false;
          this.snackbar = true;
          this.snackbarText =
            "Error submitting invoice. Please try again or contact support.";
          this.snackbarColor = "red";
        });
    },
  },
};
</script>

<style>
</style>